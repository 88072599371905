<template>
	<div>
		<LoginForm />
	</div>
</template>

<script>
import LoginForm from '@/components/templates/LoginForm.vue'
export default {
	components: {
		LoginForm
	}
}
</script>