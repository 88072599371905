<template>
	<div>
		<v-btn @click='openModal' text small class="cursor-pointer caption ma-2">Zresetuj hasło</v-btn>
		<v-dialog v-model="modal" max-width="500px">
			<v-card class="pa-4">
					<v-card-title  class="pl-0">Resetowanie hasła</v-card-title>
					<v-form>
						<v-text-field v-model="email" label="Wpisz twój email"></v-text-field>
						<v-btn
							:disabled="!isFilled"
							color="primary"
							@click="sendMail"
							:loading="isLoaded"
							>
							Wyślij
						</v-btn>
					</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	
	data() {
		return {
			isLoaded: false,
			modal: false,
			email: ''
		}
	},
	props: {
		resetFeedback: {
			type: Function
		}
	},
	methods: {
		sendMail(){
			this.isLoaded = true;
			this.$axios.post(`${this.url}api/passwords`, {
				email: this.email,
			})
			.then((el) => {
				this.isLoaded = false;
				this.resetFeedback(el.data.message)
				this.email = ''
				this.modal = false
			})
		},
		openModal(){
			this.modal = true
		},
	},
	computed: {
        isFilled(){
            const regMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return  this.email.length > 0 && regMail.test(this.email)
        }
    },
}
</script>