<template>
    <div class="login">
        <v-container>
            <v-row
                align="center"
                justify="center"
                class='center'
            >
                <v-col
                    cols="12"
                    sm="8"
                    md="4"
                >
                    <v-card>
                        <v-toolbar
                            class="indigo"
                            dark
                            flat
                        >
                        <v-toolbar-title>Logowanie</v-toolbar-title>

                        </v-toolbar>
                        <v-card-text>
                            <v-form>
                                <v-text-field
                                    label="Email"
                                    name="email"
                                    type="text"
                                    v-model="email"
                                />

                                <v-text-field
                                    id="password"
                                    label="Hasło"
                                    name="password"
                                    type="password"
                                    v-model="password"
									autocomplete="on"
                                />
                                <v-btn
                                    class="ma-2"
                                    color="secondary not-loaded"
                                    :class='{"loaded": isFilled }'
                                    @click="login"
                                    :disabled="loading"
                                    :loading="loading"
                                    v-on:keyup.enter="login">
                                    Zaloguj
                                </v-btn>
                                <p v-if="feedback">{{ feedback }}</p>
                            </v-form>
                        </v-card-text>
						<ResetPasswordModal :resetFeedback="getFeedback"/>
                </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ResetPasswordModal from '@/components/molecules/ResetPasswordModal';

export default {
    name: 'Login',
    data(){
        return{
            loader: null,
            loading: false,
            email: '',
            password: '',
            feedback: null,
			isUserExist: false,
			
        }
	},
	components: {
		ResetPasswordModal
	},
    methods:{
		getFeedback(message){
			this.feedback = message;
		},
        login(){
            this.feedback = '';
            const isEmailValid = this.checkEmailValid();
            const isPasswdValid = this.checkPasswdValid();
            if(isEmailValid && isPasswdValid){
				this.loading = true;
				
                this.$axios.post(`${this.url}api/login`, {
                    email: this.email,
                    password: this.password
				},
				{
                    
                    withCredentials: true,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
					},
				})
				.then(res => {
                if(res.data.error){
                    this.feedback = res.data.error;
                    this.loading = false;
                }else {
                    this.$store.commit('updateUser', res.data.user, res.data.authToken);
                    localStorage.setItem('user', JSON.stringify(res.data.authToken));
                    localStorage.setItem('id', res.data.user._id);
                    localStorage.setItem('role', res.data.user.role);
                    this.logIn();
                }
                }).catch(err => {
                this.feedback = err.message
                    if(err){
                        this.loading = false    
                    }
                })
            }
        },
        checkEmailValid(){
            if(this.email === '' || this.email === null){
                this.feedback = 'Email jest wymagany.'
                return false;
            }else {
                return true;
            }
        },
        async logIn(){
            this.$router.push(`/${await this.$store.getters.getUserRole}/${new Date().toISOString().substr(0, 7)}`)
        },
        checkPasswdValid(){
            if(this.password === '' || this.password === null){
                this.feedback = 'Hasło jest wymagane.'
                return false;
            }else {
                return true;
            }
        }
    },
    computed: {
        isFilled(){
            const regMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return  (this.email.length > 0 && regMail.test(this.email)) && this.password.length > 5
        }
    },
};
</script>

<style lang="scss" scoped>
    .center{
        justify-content: center;
    }
    .not-loaded{
        opacity: .5;
    }
    .loaded{
        color: #fff ;
        background-color: #1E1E1E !important;
        transition: background-color .2s opacity .2s;
        opacity: 1;
    }
	
</style>